<template>
  <div class="full-area" :class="{'feedback': feedback}">
    <FeedbackScreen
      :data="data"
      :feedback="feedback"
      v-on:retry="retry()"
      v-on:next="emitNext()"
    ></FeedbackScreen>

    <QuestionHeader
      :data="data"
      :no-question-title="true"
    ></QuestionHeader>

    <h2
      class="optional-question-title"
      :class="{'question-subtitle-strong': data.fields.questionImage}"
      v-if="data.fields.title"
    >{{data.fields.title}}</h2>

    <content class="content delay-entry">
      <el-rate
        class="enlarge-icon"
        v-model="rating"
        :colors="colors">
      </el-rate>
    </content>
    <footer>
      <SkipQuestionButton
        :location="'locate-bottom'"
        v-on:skip="selectThis('S99')"
      ></SkipQuestionButton>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Rating',

  props: [ 'data' ],

  components: {
    FeedbackScreen: () => import('Components/base/FeedbackScreen'),
    QuestionHeader: () => import('Components/base/QuestionHeader'),
    SkipQuestionButton: () => import('Components/base/SkipQuestionButton')
  },

  data () {
    return {
      feedback: '',
      rating: 0,
      locked: false,
      form: {
        type: `rating`,
        identifier: this.data.fields.identifier,
        question: this.data.fields.questionTitle,
        numberOfOptions: 5,
        id: this.data.sys.id,
        selected: [],
        timeToCompletion: null,
        timestamps: {
          start: new Date(),
          finish: null,
          valid: null
        }
      },
      colors: [ '#f56c6c', '#BFA28E', '#88D8B0' ]
    }
  },

  methods: {
    retry () {
      this.form.selected = []
      this.locked = false
      this.feedback = ''
    },
    emitNext () {
      this.$emit('next', this.form)
    },
    selectThis (item) {
      if (this.locked) {
        return
      }
      this.form.selected = [item]
    },
    next () {
      this.form.timestamps.finish = new Date()
      this.calculateScores()
    },
    validInput (value) {
      // Add simple validation: if it exists, it is valid.
      if (value && value.length) {
        this.form.timestamps.valid = new Date()
        this.form.timeToCompletion = this.form.timestamps.valid.getTime() - this.form.timestamps.start.getTime()
        return true
      } else {
        return false
      }
    },
    calculateScores () {
      this.locked = true
      var options = []
      for (var i = 1; i <= 5; i++) {
        options.push(i)
      }
      this.$store.dispatch('CALCULATE_WEIGHTED_QUESTION', {
        formula: this.data.fields.formula,
        selected: this.form.selected,
        options: options,
        maxAnswers: 1,
        skip: this.data.fields.skip
      }).then(scores => {
        this.form.weighted = scores.weighted.slice(0)
        this.form.weightedMin = scores.weightedMin
        this.form.weightedMax = scores.weightedMax
        this.feedback = scores.feedback
        this.form.skip = scores.newSkip
        if (this.data.fields.randomSkip) {
          var items = this.data.fields.randomSkip.split(',')
          this.form.skip = items[items.length * Math.random() | 0]
        }
        this.emitNext()
      }).catch(err => {
        console.log('err: ', err)
      })
    }
  },

  watch: {
    // Add a simple watch to trigger next automatically after some ms
    'form.selected': function (newVal, oldVal) {
      if (this.validInput(newVal)) {
        setTimeout(() => {
          this.next()
        }, 50)
      }
    },
    'rating': function (newVal, oldVal) {
      if (newVal) {
        this.selectThis(newVal)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  margin-bottom: -60px;
  margin-top: -60px;
  @include breakpoint($desktop) {
    margin-top: -50px;
    margin-bottom: 0;
  }
}

.enlarge-icon {
  transform: scale(2.5);
  margin-right: -6px;
  margin-top: -4em;
  @include breakpoint($tablet) {
    transform: scale(3.5);
    margin-top: -4em;
  }
  @include breakpoint($desktop) {
    transform: scale(2.5);
    margin-top: -2em;
  }
}
.contain-image {
  .img {
    background-position: center center;
    background-size: cover;
  }
}
</style>
